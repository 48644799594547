/*
1st case: New visitor
--------------------
INIT_CONFIG
SET_SOURCE
ADD_IFRAME
COOKIE_SYNC
OPEN_PRIVACY_SETTINGS
SET_STATS_PREF / SET_ADS_PREF then SYNC_PREF_WITH_IFRAME
CLOSE_PRIVACY_SETTINGS
USER_HAS_CHOSEN
SYNC_PREF_WITH_IFRAME
SEND_TRACKING_AFTER_CONSENT
SEND_TRACKING

2nd case: Returning visitor
----------------------------
INIT_CONFIG
SET_SOURCE
COOKIE_SYNC
ADD_IFRAME
SEND_TRACKING_AFTER_CONSENT
SEND_TRACKING

OTHERS ACTION
-------------
TOGGLE_ENV
TOGGLE_LOGS
*/

export default (state, action) => {
  if (typeof state === 'undefined') {
    return {
      VID: '', // Visitor Id
      sessionId: '',
      trackingKey: '',
      abTestingPopulation: '',
      abTestingPopulationStore: '',
      config: {
        env: 'prod',
        tealiumKey: '',
        logs: false, // Log dataLayer in console
        forceHidePopin: false,
        locale: 'fr',
        zone: 'fr',
        lang: {},
      },
      statsAllowed: false,
      adsAllowed: false,
      anonymousAllowed: true,
      vendorList: {},
      source: {
        campaign: '', // Id of the campaign (channel + entity + name + date, ...)
        campaignType: '', // Internal, external, advertising, ...
        campaignEntity: '', // MARKETING, SALES, BRANDING, ...
        campaignThematic: '', // SPORTS, MOVIES, ...
        referrer: '', // document.referrer basically
        partners: '',
        externalAttribution: {},
        paidAttribution: {},
      },
      campaignData: {}, // New state for storing campaign data
    };
  }

  switch (action.type) {
    case 'UPDATE_TEALIUM_KEY':
      return {
        ...state,
        config: { ...state.config, tealiumKey: action.tealiumKey },
      };
    case 'INIT_CONFIG':
      return { ...state, config: action.config };
    case 'SET_SOURCE':
      return {
        ...state,
        source: {
          ...state.source,
          campaign: action.campaign,
          campaignType: action.campaignAttributionType,
          campaignEntity: action.campaignEntity,
          campaignThematic: action.campaignThematic,
          referrer: action.referrer,
          partners: action.partners,
          externalAttribution: action.externalAttribution,
          paidAttribution: action.paidAttribution,
        },
      };
    case 'COOKIE_SYNC':
      return {
        ...state,
        ...action.data,
        VID: action.VID,
        sessionId: action.sessionId,
        trackingKey: action.trackingKey,
        abTestingPopulation: action.abTestingPopulation,
        abTestingPopulationStore: action.abTestingPopulationStore,
        authenticatedFlagFromCookie: action.authenticatedFlagFromCookie,
        source: {
          ...state.source,
          campaignType: action.campaignType,
          campaign: action.campaign,
          campaignEntity: action.campaignEntity,
          campaignThematic: action.campaignThematic,
          externalAttribution: action.externalAttribution,
          paidAttribution: action.paidAttribution,
        },
      };
    case 'SET_ANONYMOUS_PREF':
      return { ...state, anonymousAllowed: action.anonymousAllowed };
    case 'SET_ADS_PREF':
      return { ...state, adsAllowed: action.adsAllowed };
    case 'SET_STATS_PREF':
      return { ...state, statsAllowed: action.statsAllowed };
    case 'TOGGLE_ENV':
      return { ...state, config: { ...state.config, env: action.env } };
    case 'TOGGLE_LOGS':
      return { ...state, config: { ...state.config, logs: action.value } };
    case 'UPDATE_SESSION_ID':
      return { ...state, sessionId: action.sessionId };
    case 'UPDATE_TRACKING_KEY':
      return { ...state, trackingKey: action.trackingKey };
    case 'UPDATE_AUTHENTICATE_FLAG':
      return {
        ...state,
        authenticatedFlagFromCookie: action.authenticatedFlagFromCookie,
      };
    case 'SET_AB_TESTING':
      return { ...state, abTestingPopulation: action.abTestingPopulation };
    case 'SET_AB_TESTING_STORE':
      return {
        ...state,
        abTestingPopulationStore: action.abTestingPopulationStore,
      };
    case 'SET_CAMPAIGN_DATA':
      return {
        ...state,
        campaignData: action.campaignData,
      };
    case 'RESET_CAMPAIGN_DATA':
      return {
        ...state,
        campaignData: {},
      };

    case 'ADD_IFRAME':
    case 'SEND_PAGE_VIEW':
    case 'SEND_EVENT':
    case 'SYNC_PREF_WITH_IFRAME':
    case 'OPEN_PRIVACY_SETTINGS':
    case 'CLOSE_PRIVACY_SETTINGS':
    case 'SEND_TRACKING_AFTER_CONSENT':
    default:
      return state;
  }
};
