/**
 *  Tracking Tool for CANAL+ Group
 */
import {
  initConfig,
  toggleLogs,
  toggleStaging,
} from '../Actions/ConfigActions';
import {
  addIframeForCookieSync,
  handleExtendOrRenewSession,
  setAdsOptOut,
  setStatsAndAnonymousOptOut,
} from '../Actions/PrivacyActions';
import store from '../Actions/store';
import DataLayer from '../DataLayer/DataLayer';
import { initDataLayerAfterLoad } from '../DataLayer/EnrichDataLayer';
import QueueManagerForLoadEvent from '../DataLayer/QueueManagerForLoadEvent';
import { addTealiumScript } from '../Integrations/Tealium';
import { initTrackingtoolHelper } from '../PrivacyManager/helper';
import { getBrandQueryString } from '../Utils';

const queueManagerForLoadEvent = new QueueManagerForLoadEvent();

/**
 * Get config from script tag included in host page
 */
const scriptTag =
  document.currentScript ||
  document.getElementById('tt-script') ||
  document.getElementById('om-tracking'); // 'om-tracking' is for retrocompatibility
const channel = scriptTag?.getAttribute('data-channel') || '';
const tealiumKey = scriptTag?.getAttribute('data-tealium-key');
const locale = scriptTag?.getAttribute('data-locale') || 'fr'; // Default value set to FR for retrocompatibility
const zone = scriptTag?.getAttribute('data-zone') || 'fr'; // Default value set to FR for retrocompatibility
const includePopin = scriptTag?.getAttribute('data-include-cnil') !== null;
const src = scriptTag?.getAttribute('src');
const brandQueryString = getBrandQueryString(src);
const brand = scriptTag?.getAttribute('data-brand') || brandQueryString || '';
const environment = scriptTag?.getAttribute('data-environment') || 'prod';

// Check if the conf is a copy-paste from the doc ;)
if (tealiumKey === 'EXAMPLE' || channel === 'EXAMPLE') {
  throw new Error('Config is missing or example value used for configuration.');
}
// Check if the user has already given his consent and if it's Didomi

/*
  1- Website fills up an array of tracking objects and events (window.trackingQueue, window.eventQueue, window.consentCallbackQueue)
  2- Config, store and dataLayer are intialized
  3- Iframe is added to sync user prefs stored in cookies and store is initialized
  4- We wait for the user's consent expressed with an interaction (click). Once the user has interacted, we define new function sendPageView & sendEvent
  5- Tealium scripts are loaded but not fired
  6- Extend or renew the user session when the page is visible every X minutes
*/

// 2- Config, store and dataLayer are intialized
const ttDataLayer = new DataLayer(channel);

initConfig(store, includePopin, tealiumKey, zone, locale, brand, environment);

// Expose actions on window to be called by user in the console or by the app whenever they want
window.ttStore = store;
window.ttToggleLogs = toggleLogs;
window.ttToggleStaging = toggleStaging;
window.setPreferenceAnalytics = (value) =>
  setStatsAndAnonymousOptOut(store, value);
window.setPreferenceAds = (value) => setAdsOptOut(store, value);
window.queueManagerForLoadEvent = queueManagerForLoadEvent; // The queue will be executed at each sendPageView
window.ttDataLayer = ttDataLayer;

initDataLayerAfterLoad({ store, pageURL: window.location.href });
initTrackingtoolHelper();

// 3- Iframe is added to sync user prefs stored in cookies
addIframeForCookieSync(store, () => {
  // 4- Stop the script if the user has not given his consent only for Didomi
  if (window.Didomi && window.Didomi?.shouldUserStatusBeCollected()) {
    return;
  }

  // 5- Tealium scripts are loaded even without the user's consent BUT not fired
  if (tealiumKey) {
    addTealiumScript(store);
  }

  // 6- Extend or renew the user session when the page is visible every X minutes
  // Use case: a user is playing a video for 2 hours, we need to extend his session
  handleExtendOrRenewSession(store);
});
