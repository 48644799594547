import { initAfterUserConsent } from '../Actions/TrackingActions';
import { BRAND_TIMVISION } from '../PrivacyManager/Constants';
import {
  addPrivacyManager,
  removePrivacyManager,
} from '../PrivacyManager/handlePrivacyManager';
import { isPassAuthenticated, isPassIncluded } from '../Utils';
import { setAdsOptOut, setAnonymous, setStatsOptOut } from './PrivacyActions';

export const handlePopinDisplay = (
  store,
  displayPopinFromCookies,
  urlChange
) => {
  // Do not display the popin on the cookie policy page BUT do not make any consent choice
  if (window.location.href.includes('/cookies/')) {
    removePrivacyManager();
    return;
  }

  // Do not do anything if the Didomi user consent should not be collected
  if (window.Didomi?.shouldUserStatusBeCollected()) {
    return;
  }

  // Remove urlChange listener before making a consent choice so it is not triggered
  // after interacting with the popin
  if (urlChange) {
    urlChange.disconnect();
  }

  const state = store.getState();
  let displayPopin = displayPopinFromCookies;

  // if on TIM, don't display popin and set corresponding values of tracking cookies
  if (state.config.brand === BRAND_TIMVISION) {
    displayPopin = false;
    setAnonymous(store, true);
    setStatsOptOut(store, true);
    setAdsOptOut(store, false);
  }
  if (isPassIncluded()) {
    let waitForPassJSONExecuted = false;

    if (!waitForPassJSONExecuted) {
      window.waitForPassJSON(() => {
        waitForPassJSONExecuted = true;

        // If the user is logged in, use the value from their askForConsent to handle the popin display
        if (
          isPassAuthenticated() &&
          window.passJSON?.askForConsent !== undefined
        ) {
          displayPopin = window.passJSON?.askForConsent;
        }

        if (displayPopin && !state.config.forceHidePopin) {
          addPrivacyManager();
        } else {
          // The user previously agreed so fire all the tags!
          initAfterUserConsent(store, true);
        }
      });
    }
  } else if (displayPopin && !state.config.forceHidePopin) {
    addPrivacyManager();
  } else {
    // The user previously agreed so fire all the tags!
    initAfterUserConsent(store, true);
  }
};
