/**
 * PrivacyManager's total optout function
 */
import {
  setAdsOptOut,
  setAnonymous,
  setStatsOptOut,
  syncUserPrefWithIframe,
  updateSessionId,
} from '../Actions/PrivacyActions';
import {
  initAfterUserConsent,
  sendEvent,
  sendPageView,
  storeUserChoiceOnTopDomainCookie,
} from '../Actions/TrackingActions';
import {
  COOKIE_NAME_ADS,
  COOKIE_NAME_ANONYMOUS,
  COOKIE_NAME_DEVICE_ID,
  COOKIE_NAME_SESSION_ID,
  COOKIE_NAME_STATS,
  DEFAULT_COOKIE_DURATION,
  SESSION_ID_DURATION_DAYS,
} from '../PrivacyManager/Constants';
import { cleanCookies, getCookie, setCookie } from '../Utils';

/**
 * Initializes tracking tools and assigns functions to the `window` object for global access.
 */
export const initTrackingtoolHelper = () => {
  Object.assign(window, {
    getAnonymousMeasure,
    getTealiumKey,
    setAdsConsent,
    setAnonymousMeasure,
    setStatsConsent,
    setTealiumKey,
    ttSetUserConsent,
    ttSetSessionId,
  });
};

/**
 * Sets the user's anonymous measurement value
 * - If **anonymousAllowed=true** then we can send anonymous data for analytics
 * - If **anonymousAllowed=false** then we do not send any data
 * @param {boolean} anonymousAllowed - The anonymous measurement value
 */
const setAnonymousMeasure = (anonymousAllowed) => {
  const store = window.ttStore;

  if (anonymousAllowed) {
    Object.assign(window, {
      sendPageView,
      sendEvent,
    });
  } else {
    window.sendPageView = () => {};
    window.sendEvent = () => {};
    cleanCookies([COOKIE_NAME_DEVICE_ID, COOKIE_NAME_SESSION_ID]);
  }

  setCookie(
    COOKIE_NAME_ANONYMOUS,
    anonymousAllowed.toString(),
    DEFAULT_COOKIE_DURATION
  );
  setAnonymous(store, anonymousAllowed);
  if (!anonymousAllowed) {
    setStatsOptOut(store, false);
  }

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(
    new CustomEvent('userChangedConsent', { detail: state })
  );
};

/**
 * Returns the user's anonymous value stored in the **anonymous** cookie, or **true** if there isn't any cookie stored.
 * @returns {boolean} - Returns true if anonymous tracking is allowed, false otherwise.
 */
const getAnonymousMeasure = () => getCookie(COOKIE_NAME_ANONYMOUS) !== 'false';

/**
 * Retrieves the Tealium key from the tracking store state.
 * @returns {string|undefined} - Returns the Tealium key or undefined if not available.
 */
const getTealiumKey = () => {
  const store = window.ttStore;
  const state = store.getState();

  return state.config.tealiumKey;
};

/**
 * Sets the user's Tealium key in the store state.
 * @param {string} tealiumKey - The Tealium key to be set.
 */
const setTealiumKey = (tealiumKey) => {
  const store = window.ttStore;
  return store.dispatch({ type: 'UPDATE_TEALIUM_KEY', tealiumKey });
};

/**
 * Sets the user's stats consent value.
 * @param {boolean} statsAllowed - True to allow analytics measurement, false to disallow.
 */
const setStatsConsent = (statsAllowed) => {
  const store = window.ttStore;
  setCookie(
    COOKIE_NAME_STATS,
    statsAllowed.toString(),
    DEFAULT_COOKIE_DURATION
  );
  setStatsOptOut(store, statsAllowed);

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(
    new CustomEvent('userChangedConsent', { detail: state })
  );
};

/**
 * Sets the user's ads consent value.
 * @param {boolean} adsAllowed - True to allow ads, false to disallow.
 */
const setAdsConsent = (adsAllowed) => {
  const store = window.ttStore;
  setCookie(COOKIE_NAME_ADS, adsAllowed.toString(), DEFAULT_COOKIE_DURATION);
  setAdsOptOut(store, adsAllowed);

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(
    new CustomEvent('userChangedConsent', { detail: state })
  );
};

/**
 * Sets the user's session ID.
 * @param {string} sessionId - The session ID to set.
 */
const ttSetSessionId = (sessionId) => {
  const store = window.ttStore;

  if (sessionId) {
    // clean session ID cookies and set the new session ID
    cleanCookies([COOKIE_NAME_SESSION_ID]);
    setCookie(
      COOKIE_NAME_SESSION_ID,
      sessionId,
      SESSION_ID_DURATION_DAYS,
      window.location.host.replace('www.', '.')
    );

    updateSessionId(store, sessionId);
  }
};

/**
 * Sets the user's consent values.
 * @param {Object} consentData - An object containing `anonymousAllowed`, `statsAllowed`, and `adsAllowed` flags.
 */
const ttSetUserConsent = ({ anonymousAllowed, statsAllowed, adsAllowed }) => {
  const store = window.ttStore;

  // Set the user's consent values analytics, trackingPub, and anonymousTracking
  setAnonymousMeasure(anonymousAllowed);
  setAdsConsent(adsAllowed);
  setStatsConsent(statsAllowed);

  // Send Popin display and interaction events
  sendPopinDisplayEvent(statsAllowed, statsAllowed);
  sendPopinInteractionEvent(statsAllowed, adsAllowed);

  //  CANAL+ respects the GDPR regulation
  // We add the tracking ONLY if the user expressed their consent
  initAfterUserConsent(store, true);
};

/**
 * Sets campaign information for tracking purposes.
 * @param {Object} campaignData - An object containing campaign details (e.g., `utm_source`, `utm_medium`, `utm_campaign`, `hostname`, `origref`).
 */
const ttSetCampaign = (campaignData = {}) => {
  const store = window.ttStore;
  // dispatch action to update campaign data in the store
  store.dispatch({ type: 'SET_CAMPAIGN_DATA', campaignData });
};

/**
 * Sets tracking data with specific actions based on the type of data.
 * @param {Object|string} payload - Data payload for the tracking action.
 * - If `type` is `"campaign"`, `payload` should be an object containing campaign details:
 *   - `{string} utm_source` - The source of the campaign (e.g., "google").
 *   - `{string} utm_medium` - The medium of the campaign (e.g., "cpc").
 *   - `{string} utm_campaign` - The name of the campaign.
 * - If `type` is `"sessionId"`, `payload` should be a `{string}` representing the session ID.
 * - If `type` is `"userConsent"`, `payload` should be an object containing consent flags:
 *   - `{boolean} anonymousAllowed` - Indicates if anonymous measurement is allowed.
 *   - `{boolean} statsAllowed` - Indicates if analytics measurement is allowed.
 *   - `{boolean} adsAllowed` - Indicates if ads are allowed.
 * @param {string} [type] - Optional type to specify the kind of data update. Options: "campaign", "sessionId", "userConsent".
 */
window.ttSetTrackingData = (payload, type) => {
  switch (type) {
    case 'campaign':
      if (
        typeof payload !== 'object' ||
        !payload.utm_source ||
        !payload.utm_medium ||
        !payload.utm_campaign
      ) {
        console.warn(
          'Invalid payload for "campaign" type. Expected an object with mandatory properties: utm_source, utm_medium, utm_campaign.'
        );
        return;
      }
      ttSetCampaign(payload);
      break;

    case 'sessionId':
      if (typeof payload !== 'string') {
        console.warn(
          'Invalid payload for "sessionId" type. Expected a string representing the session ID.'
        );
        return;
      }
      ttSetSessionId(payload);
      break;

    case 'userConsent':
      if (
        typeof payload !== 'object' ||
        typeof payload.anonymousAllowed !== 'boolean' ||
        typeof payload.statsAllowed !== 'boolean' ||
        typeof payload.adsAllowed !== 'boolean'
      ) {
        console.warn(
          'Invalid payload for "userConsent" type. Expected an object with boolean properties: anonymousAllowed, statsAllowed, adsAllowed.'
        );
        return;
      }
      ttSetUserConsent(payload);
      break;

    default:
      console.warn('Unknown tracking data type:', type);
      console.warn(
        'You can use one of the following types: campaign, sessionId, userConsent'
      );
  }
};
