import { extendOrRenewSessionAction } from '../Actions/PrivacyActions';

// If there is a change in consent settings, we want to synchronize it with the iframe (cookies)
export const consentChangeMiddleware = (_store) => (next) => (action) => {
  if (
    typeof action.statsAllowed === 'undefined' &&
    typeof action.adsAllowed === 'undefined' &&
    typeof action.anonymousAllowed === 'undefined'
  ) {
    return next(action);
  }
  next(action);
};

// Extend the sessionId's cookie with each tracking hit
export const extendOrRenewSessionMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  if (
    (action.type === 'SEND_PAGE_VIEW' || action.type === 'SEND_EVENT') &&
    state.anonymousAllowed
  ) {
    extendOrRenewSessionAction();
  }
  next(action);
};
